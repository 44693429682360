
.green-header{
  color: white;
  background: green;
}
.blue-header{
  color: white;
  background: blue;
}
.red-header{
  color: white;
  background: red;
}

.orange-header{
  color: white;
  background: orangered;
}

